<template>
  <div class="agreement-page">
    <div class="content">
      <h1 class="title">
        {{ langVerificationCode.google_cancellation_title }}
      </h1>
      <p class="pre-wrap part1">
        {{
          langVerificationCode.google_cancellation_tips.replace("\\n", "\r\n")
        }}
      </p>
      <p class="part-normal">
        1.{{ langVerificationCode.google_cancellation_tips1 }}
      </p>
      <p class="part-normal">
        2.{{ langVerificationCode.google_cancellation_tips2 }}
      </p>
      <p class="part-normal">
        3.{{ langVerificationCode.google_cancellation_tips3 }}
      </p>
      <p class="part-normal">
        4.{{ langVerificationCode.google_cancellation_tips4 }}
      </p>
      <p class="pre-wrap part-normal">
        5.{{
          langVerificationCode.google_cancellation_tips5
            .replace("\\n", "\r\n")
            .replace("\\n", "\r\n")
            .replace("\\n", "\r\n")
            .replace("\\n", "\r\n")
        }}
      </p>
      <p class="part-normal">
        6.{{ langVerificationCode.google_cancellation_tips6 }}
      </p>
      <p class="part-normal">
        7.{{ langVerificationCode.google_cancellation_tips7 }}
      </p>
    </div>
    <div class="fixed-button">
      <div class="privacyCheckBox">
        <!-- 勾选 -->
        <label class="check-label" for="privacy" @click="checkFn">
          <div :class="[isChecked ? 'check-bg' : 'no-check-bg']">
            <img
              v-show="isChecked"
              class="img"
              src="@/assets/images/alexa/ic_16_hook@3x.png"
            />
          </div>
          <input type="checkbox" v-model="isChecked" id="privacy" />
        </label>
        <!-- 描述 -->
        <div class="desc-r">
          <p class="privacyLabel">
            {{ langVerificationCode.google_cancellation_confirm_tips }}
          </p>
        </div>
      </div>
      <el-button
        v-dbClick
        @click="submit"
        class="confirm-button"
        :class="{ 'el-disabled': !isChecked }"
        type="primary"
        round
        >{{ langVerificationCode.wordAgree }}
      </el-button>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      isChecked: false,
      account: "",
      from: "",
    };
  },
  computed: {
    ...mapState("alexaLogin", ["tabIndex", "languagePackage"]),
    langVerificationCode() {
      return this.languagePackage[this.tabIndex];
    },
  },
  components: {},
  mounted() {
    this.account = this.$route.query.account;
    this.from = this.$route.query.from;
  },
  methods: {
    checkFn() {
      this.isChecked = !this.isChecked;
    },
    submit() {
      if (!this.isChecked) return;
      this.$router.push(
        `/${this.$commonFunc.getUrlChannelAppID()}/get-code${this.$commonFunc.linkUrlParams()}`
      );
    },
  },
};
</script>

<style lang="less" scoped>
.pre-wrap {
  white-space: pre-wrap;
}
.agreement-page {
  // display: flex;
  flex-direction: column;
  height: 100%;
  overflow: scroll;
}

.content {
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: 27px 16px 0px 16px;
  margin-bottom: 108px;
}
.title {
  font-size: 18px;
  color: #212121;
  letter-spacing: 0;
  font-weight: 600;
  text-align: center;
  margin-bottom: 27px;
}

.part1 {
  font-size: 14px;
  color: #212121;
  letter-spacing: 0;
  line-height: 50px;
  font-weight: 600;
  margin-bottom: 40px;
}

.part-normal {
  white-space: pre-wrap;
  font-size: 14px;
  color: #212121;
  letter-spacing: 0;
  line-height: 50px;
  font-weight: 400;
}
.checkbox {
  margin-top: 20px;
}

.fixed-button {
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-left: 16px;
  padding-right: 16px;
  //   left: 50%;
  //   transform: translateX(-50%);
  align-items: center;
  z-index: 999;
  width: 100%;
  background-color: #fff;
}

.confirm-button {
  width: 100%;
  height: 44px;
  border: none;
  margin-top: 16px;
  margin-bottom: 10px;
  box-sizing: border-box;
  &.el-disabled {
    background-color: #a0cfff;
  }
}
button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
.privacyCheckBox {
  display: flex;
  margin-top: 20px;
  line-height: 16px;
  font-size: 12px;
  .check-label {
    position: relative;
    width: 18px;
    height: 18px;
    .check-bg,
    .no-check-bg {
      box-sizing: border-box;
      position: absolute;
      left: 0;
      top: 0;
      border-radius: 50%;

      width: 100%;
      height: 100%;
    }
    .check-bg {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #267aff;
      .img {
        width: 18px;
        height: 18px;
      }
    }
    .no-check-bg {
      border: 1px solid #999999;
    }
    input {
      opacity: 0;
      width: 100%;
      height: 100%;
    }
  }
  .desc-r {
    flex: 1;
  }
}

.privacyLabel {
  margin-left: 8px;
  margin-right: 8px;
  color: silver;
  font-size: 14px;
  color: #a7acb1;
  font-weight: 400;
}

.privacyButton {
  color: black;
}
#privacy {
  margin: 0;
}
</style>
